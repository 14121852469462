import React from "react";
import ChatBotIcon from "../assets/chatbot-icon.svg";
import Spinner from "./Spinner";
import Paticles from "./Paticles";
const LoadingMessage = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <Paticles />
      {/* titel */}
      <div className="w-fit flex justify-start gap-2 items-center z-10 mt-14">
        <p className="text-4xl font-bold text-gray-600 mb-1 ">
          A<span className="text-yellow-400">I</span> TEAM
        </p>
        <Spinner />
      </div>
      <span className=" text-yellow-400 font-bold mb-14">
        We Democratize Industrial AI
      </span>

      {/* chatbot */}
      <div className="chat chat-start z-10 md:w-full w-8/12">
        <div className="chat-image avatar">
          <div className="w-12 md:w-14 rounded-full">
            <img alt="Tailwind CSS chat bubble component" src={ChatBotIcon} />
          </div>
        </div>

        <div className="chat-bubble bg-yellow-400 text-gray-800  font-semibold  text-xs md:tetx-sm">
          Innovating Industry with AI Automation, Education and Collaboration
        </div>
      </div>

      {/*  Contant message */}
      <p className="text-black z-20 absolute bottom-10 text-xs md:text-sm ">
        Contact us to learn more at{" "}
        <a href="mailto:ko@aiteam.gr" className="text-yellow-400">
          Contact Us
        </a>
      </p>
    </div>
  );
};

export default LoadingMessage;
