import LoadingMessage from "./components/LoadingMessage";
function App() {
  return (
    <div className=" w-screem h-screen bg-white flex flex-col justify-center items-center">
      <LoadingMessage />
    </div>
  );
}

export default App;
