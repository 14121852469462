import { useState, CSSProperties } from "react";
import { Grid } from "react-loader-spinner";

const Spinner = () => {
  return (
    <Grid
      visible={true}
      height="27"
      width="27"
      color="#facc15"
      ariaLabel="grid-loading"
      radius="12.5"
      wrapperStyle={{}}
      wrapperClass="grid-wrapper"
    />
  );
};

export default Spinner;
